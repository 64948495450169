import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ParameterEdit = () => {
    const [procedures, setProcedures] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('ACTIVE');

    useEffect(() => {
        fetchProcedures();
    }, []);

    const fetchProcedures = async () => {
        const res = await axios.get('/procedures');
        setProcedures(res.data);
    };

    const addProcedure = async () => {
        await axios.post('/procedures', { name, description, status });
        fetchProcedures();
        setName('');
        setDescription('');
    };

    const deleteProcedure = async (id) => {
        await axios.delete(`/procedures/${id}`);
        fetchProcedures();
    };

    return (
        <div>
            <h2>Procedure Type Management</h2>
            <input 
                type="text" 
                value={name} 
                onChange={e => setName(e.target.value)} 
                placeholder="Name" 
            />
            <input 
                type="text" 
                value={description} 
                onChange={e => setDescription(e.target.value)} 
                placeholder="Description" 
            />
            <button onClick={addProcedure}>Add Procedure</button>
            <ul>
                {procedures.map(proc => (
                    <li key={proc.ID}>
                        <strong>{proc.name}</strong> - {proc.DESCRIPTION} ({proc.STATUS})
                        <button onClick={() => deleteProcedure(proc.ID)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ParameterEdit;
