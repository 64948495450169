import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import LLMDetailsTable from "../BatchModeMlGen/LLMDetailsTable";
import "./ApiKeyManager.css";

const llmProviders = [
  { value: "GROQ", label: "Groq" },
  { value: "OLLAMA", label: "Ollama" },
  { value: "OPENROUTER", label: "OpenRouter" },
  { value: "MISTRAL", label: "Mistral" },
  { value: "DEEPSEEK", label: "DeepSeek" },
  { value: "GPT2", label: "GPT2" },
  { value: "LLAMA3.2", label: "Llama3.2" },
];

const AddApiKey = ({ onProviderSelect, onClose }) => {
  const [apiKeys, setApiKeys] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [userId, setUserId] = useState("");
  const [firmId, setFirmId] = useState("");
  const [llmProvider, setLlmProvider] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const userid = Cookies.get("userid");
  const firmid = Cookies.get("firmid");
  const [llmDetails, setLlmDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const usertype = Cookies.get("usertype");
    if (!userid) {
      alert("Please login");
      navigate("/login");
    } else if (usertype === "USERAPP") {
      alert("Login with Business User Credentials");
      navigate("/login");
    } else {
      setUserId(userid || "");
      setFirmId(firmid || "");
      fetchApiKeys(userid, firmid);
    }
  }, [navigate]);

  useEffect(() => {
    fetchLLMDetails();
  }, []);

  const fetchLLMDetails = async () => {
    if (!userid || !firmid) {
      setError("USERID and FIRMID cookies are missing.");
      return;
    }
    try {
      const response = await axios.get("/api/get-llm-details", {
        params: { userid, firmid },
      });
      setLlmDetails(response.data);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    }
  };

  const handleActivate = (provider) => {
    onProviderSelect(provider);
  };

  const fetchApiKeys = async (userId, firmId) => {
    try {
      const response = await axios.get(
        `/get-api-keys?USERID=${userId}&FIRMID=${firmId}`
      );
      setApiKeys(response.data);
    } catch (err) {
      console.error("Error fetching API keys:", err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId || !firmId || !llmProvider || !apiKey) {
      setError("All fields are required.");
      setMessage(null);
      return;
    }
    try {
      const response = await axios.post("/add-api-key", {
        USERID: userId,
        FIRMID: firmId,
        LLM_PROVIDER: llmProvider,
        API_KEY: apiKey,
      });

      setMessage("API Key added successfully!"); // Success message
      setError(null);
      setApiKey("");
      setLlmProvider("");
      fetchApiKeys(userId, firmId);
      fetchLLMDetails();
      handleActivate(llmProvider);

      // Hide success message after 3 seconds
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.message || "Error adding API Key.");
      setMessage(null);
    }
  };

  return (
    <div className="apikey-modal-overlay">
      <div className="apikey-modal-container">
        <div className="apikey-modal-header">
          <h2>Add API Key</h2>
          <button className="apikey-modal-close" onClick={onClose}>×</button>
        </div>

        {error && <p className="error-message">{error}</p>}
        {message && <p className="success-message">{message}</p>}

        <form onSubmit={handleSubmit} className="apikey-form">
          <select value={llmProvider} onChange={(e) => setLlmProvider(e.target.value)}>
            <option value="">Select LLM Provider</option>
            {llmProviders.map((provider) => (
              <option key={provider.value} value={provider.value}>
                {provider.label}
              </option>
            ))}
          </select>

          <input type="text" placeholder="Enter API Key" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />

          <button type="submit" className="submit-button">Submit</button>
        </form>

        <div className="apikey-list">
          <h3>Existing API Keys</h3>
          <ul>
            {apiKeys.map((key, index) => (
              <li key={index}>
                {key.LLM_PROVIDER}: {key.API_KEY}
              </li>
            ))}
          </ul>
        </div>

        <LLMDetailsTable onActivate={handleActivate} fetchLLMDetails={fetchLLMDetails} llmDetails={llmDetails} />
      </div>
    </div>
  );
};

export default AddApiKey;
