import React, { useEffect, useState } from 'react';
import './LabTestScreen.css';
import LabForm from './Labform'; 
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Don't forget to import axios

const Lab = ({ onSelectTest }) => {
  const [showLabForm, setShowLabForm] = useState(false);
  const [selectedTest, setSelectedTest] = useState('');
  const [testid, setTestid] = useState('');
  const [data, setData] = useState([]); // Store the fetched data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userid = Cookies.get('userid');
  const navigate = useNavigate();
  

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } 
  }, [userid, navigate]);

  useEffect(() => {
    const fetchEligibleData = async () => {
      try {
        const response = await axios.get('/pindex/validate');
        setData(response.data);  // Store the fetched data
      } catch (error) {
        setError('Error fetching data');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEligibleData();
  }, []);

  const handleTestClick = (aiProgram, parentId) => {
    console.log("Selected test:", aiProgram, "with Parent ID:", parentId);
    setSelectedTest(aiProgram); // Set the selected test
    setTestid(parentId); // Set the selected test ID
    setShowLabForm(true); // Show the LabForm component
    
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="centered">
      <h1>Personal Index</h1>
      <div className="buttonContainer">
        {/* Render buttons dynamically for each AI_PROGRAM and PARENT_ID */}
        {data.map((item, index) => (
          <div key={index} className="buttonWrapper">
            <button
              className="testButton Air"
              onClick={() => handleTestClick(item.AI_PROGRAM, item.PARENT_ID)}
            >
              {item.AI_PROGRAM} {/* Display the AI_PROGRAM on the button */}
            </button>
          </div>
        ))}
      </div>

      {showLabForm && (
        <LabForm selectedTest={selectedTest} onSelectTest={onSelectTest} testid={testid}   />
      )}
    </div>
  );
};

export default Lab;
