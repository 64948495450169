import React, { useState } from 'react';
import axios from 'axios';

const GenerateMLComponent = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const generateResponse = await axios.post('/generate/frontend/processing', {
                diseases: 'Diabetes',             
                
                confirmedLlm: 'GROQ',
                USERID: 1254,
                FIRMID: 5
                
            });

            console.log('[INFO] API Response:', generateResponse.data);

            const { apiUrl, headers, data } = generateResponse.data;
            const axiosResponse = await axios.post(apiUrl, data, { headers });
            setResponse(axiosResponse.data);
            console.log('[INFO] LLM Response:', axiosResponse.data);
        } catch (error) {
            console.error('[ERROR] API Call Failed:', error);
            setResponse({ error: 'Failed to generate ML Code' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2>Generate ML Code</h2>
            <button onClick={fetchData} disabled={loading}>
                {loading ? 'Processing...' : 'Generate Code'}
            </button>
            {response && (
                <div>
                    <h3>API Response:</h3>
                    <pre>{JSON.stringify(response, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default GenerateMLComponent;
