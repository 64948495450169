import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DiseaseParameterManager.css';

function DiseaseParameterManager({ diseaseProp, llmName, onClose }) {
    const [diseaseName, setDiseaseName] = useState(diseaseProp || '');
    const [parentId, setParentId] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [newParameter, setNewParameter] = useState('');
    const [editingParameter, setEditingParameter] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        const fetchDiseaseDetails = async () => {
            if (!diseaseName) {
                setIsLoading(false);
                setHasLoaded(true);
                return;
            }
            
            setIsLoading(true);
            try {
                const { data } = await axios.get('/api/diseases');
                const disease = data.find(d => d.AI_PROGRAM === diseaseName);
                if (disease) {
                    setParentId(disease.PARENT_ID);
                }
            } catch (error) {
                console.error('Error fetching diseases:', error);
            } finally {
                setIsLoading(false);
                setHasLoaded(true);
            }
        };

        fetchDiseaseDetails();
    }, [diseaseName]);

    useEffect(() => {
        const fetchParameters = async () => {
            if (!parentId) return;

            setIsLoading(true);
            try {
                const { data } = await axios.get(`/api/parameters/${parentId}`);
                setParameters(data);
            } catch (error) {
                console.error('Error fetching parameters:', error);
            } finally {
                setIsLoading(false);
                setHasLoaded(true);
            }
        };

        fetchParameters();
    }, [parentId]);

    const submitDisease = async () => {
        if (!diseaseName.trim()) return;

        setIsLoading(true);
        try {
            const { data } = await axios.post('/api/add-disease', {
                diseaseName, llmName, userid: 1254, firmid: 5
            });
            if (data.success) {
                setDiseaseName(data.disease);
                setParentId(data.parentId);
            } else {
                alert('Failed to add disease.');
            }
        } catch (error) {
            console.error('Error adding disease:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const addParameter = async () => {
        if (!newParameter.trim()) return;
        
        setIsLoading(true);
        try {
            const { data } = await axios.post('/api/parameters', {
                action: 'add', name: newParameter, parent: parentId
            });
            setParameters([...parameters, { procedure_type_id: data.id, name: newParameter }]);
            setNewParameter('');
        } catch (error) {
            console.error('Error adding parameter:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteParameter = async (id) => {
        setIsLoading(true);
        try {
            const { data } = await axios.post('/api/delete-parameter', { procedure_type_id: id });
            if (data.success) {
                setParameters(parameters.filter(param => param.procedure_type_id !== id));
            } else {
                alert('Failed to delete parameter.');
            }
        } catch (error) {
            console.error('Error deleting parameter:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const startEditing = (param) => {
        setEditingParameter(param.procedure_type_id);
        setEditedName(param.name);
    };

    const saveEdit = async (id) => {
        setIsLoading(true);
        try {
            await axios.post('/api/parameters', {
                action: 'edit', procedure_type_id: id, name: editedName
            });
            setParameters(parameters.map(param => 
                param.procedure_type_id === id ? { ...param, name: editedName } : param
            ));
            setEditingParameter(null);
            setEditedName('');
        } catch (error) {
            console.error('Error editing parameter:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="disease-manager-container">
            <h1 className="disease-manager-title">ML Code Parameter Management</h1>
            <button className="close-button" onClick={onClose}>✖</button>
            {!hasLoaded ? (
                <div className="loading-spinner"></div>
            ) : (
                parentId ? (
                    <div className="parameter-section">
                        <h2 className="parameter-title">Parameters for {diseaseName}</h2>
                        <ul className="parameter-list">
                            {parameters.map(param => (
                                <li key={param.procedure_type_id} className="parameter-item">
                                    {editingParameter === param.procedure_type_id ? (
                                        <>
                                            <input
                                                type="text"
                                                className="parameter-input"
                                                value={editedName}
                                                onChange={e => setEditedName(e.target.value)}
                                            />
                                            <button className="save-button" onClick={() => saveEdit(param.procedure_type_id)}>Save</button>
                                            <button className="cancel-button" onClick={() => setEditingParameter(null)}>Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            <span className="parameter-name">{param.name}</span>
                                            <div className="parameter-actions">
                                                <button className="edit-button" onClick={() => startEditing(param)}>Edit</button>
                                                <button className="delete-button" onClick={() => deleteParameter(param.procedure_type_id)}>Delete</button>
                                            </div>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                        <div className="add-parameter">
                            <input
                                type="text"
                                className="parameter-input"
                                value={newParameter}
                                onChange={e => setNewParameter(e.target.value)}
                                placeholder="New parameter"
                            />
                            <button className="add-button" onClick={addParameter} disabled={isLoading}>
                                {isLoading ? "Adding..." : "Add Parameter"}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="disease-form">
                        <p className="info-message">
                            ML code not found in the system. Please generate parameters.
                        </p>
                        <input
                            type="text"
                            className="disease-input"
                            value={diseaseName}
                            onChange={e => setDiseaseName(e.target.value)}
                            placeholder="Enter disease name"
                        />
                        <button className="disease-button" onClick={submitDisease}>
                            {isLoading ? "Processing..." : "Generate Parameters"}
                        </button>
                    </div>
                )
            )}
        </div>
    );
}

export default DiseaseParameterManager;
