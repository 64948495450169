import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const ImageGenerator = () => {
    const [prompt, setPrompt] = useState("");
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [logs, setLogs] = useState([]);
    const [processId, setProcessId] = useState(null);
    const [status, setStatus] = useState(null);

    const userid = Cookies.get("userid");
    const firmid = Cookies.get("firmid");
    const portalid = Cookies.get("portalid");
    const userEmailID = Cookies.get("userEmailID");

    const [notifyByEmail, setNotifyByEmail] = useState(false);
    const [userEmail, setUserEmail] = useState(null);

    console.log("userEmailID", userEmailID)

    const navigate = useNavigate();

    console.log("userid", userid);

    useEffect(() => {
        if (!userid) {
            alert("Please login");
            navigate('/login');
        }
    }, [userid, navigate]);

    const getUserEmailFromCookies = () => {
        return Cookies.get("userEmailID") || null;
    };

    useEffect(() => {
        setUserEmail(getUserEmailFromCookies());
    }, []);

    useEffect(() => {
        if (processId) {
            const interval = setInterval(async () => {
                try {
                    // Fetch logs
                    const logResponse = await axios.get('/fetch-logs', {
                        params: { process_id: processId, userid, firmid }
                    });
                    setLogs(logResponse.data.logs);

                    // Check process status
                    const statusResponse = await axios.get('/fetch-status', {
                        params: { process_id: processId, userid, firmid }
                    });
                    setStatus(statusResponse.data.status);

                    if (statusResponse.data.status === "COMPLETED") {
                        clearInterval(interval);
                        await fetchGeneratedImage();
                        setLoading(false); // Stop loading when image is fetched
                    }
                } catch (err) {
                    console.error("Error fetching logs or status:", err);
                    setLoading(false);
                }
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [processId]);

    const handleGenerate = async () => {
        if (!prompt.trim()) {
            setError("Please enter a prompt.");
            return;
        }

        setLoading(true);
        setError(null);
        setImage(null);
        setLogs([]);
        const newProcessId = uuidv4();
        setProcessId(newProcessId);

        try {

            const requestData = { process_id: newProcessId, prompt, userid, firmid, portalid };

            // Include email if notify is checked and email exists
            if (notifyByEmail) {
                const email = getUserEmailFromCookies();
                setUserEmail(email);
                if (email) {
                    requestData.email = email;
                }
            }
            // await axios.post('/proxy-generate-image', { process_id: newProcessId, prompt, userid, firmid, portalid });
            await axios.post('/proxy-generate-image', requestData);

            // setLoading(false);
        } catch (err) {
            setError("Failed to start image generation. Please try again.");
            console.error("Error starting image generation:", err);
        }
    };

    const fetchGeneratedImage = async () => {
        try {
            const response = await axios.get('/fetch-last-generated-image', {
                params: { userid, firmid }
            });
            if (response.data && response.data.path) {
                setImage(response.data.path);
            }
        } catch (err) {
            console.error("Error fetching generated image:", err);
        }
    };



    return (
        <div className="image-generator-container">
            <h1 className="image-generator-title">AI Image Generator</h1>
            <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your prompt..."
                className="image-generator-input"
            />

            {/* Checkbox for email notifications */}
            <div className="email-notification">
                <input
                    type="checkbox"
                    id="emailNotify"
                    checked={notifyByEmail}
                    onChange={() => {
                        setNotifyByEmail(!notifyByEmail);
                        if (!userEmail) {
                            setUserEmail(getUserEmailFromCookies());
                        }
                    }}
                />
                <label htmlFor="emailNotify"> Notify me via email when the image is generated</label>
            </div>

            {/* Email message */}
            {notifyByEmail && (
                <p className="email-message">
                    {userEmail ? (
                        `Your email is ${userEmail}. You will be notified when the image is generated.`
                    ) : (
                        <>

                        </>
                    )}
                </p>
            )}

            {notifyByEmail && (

                <>
                    update/add email using this
                    <a href="https://myblocks.in/editaccountprofilebusiness" target="_blank" rel="noopener noreferrer"> link</a>.
                </>

            )}




            <button
                onClick={handleGenerate}
                className="image-generator-button generate-button"
                disabled={loading}
            >
                {loading ? "Generating..." : "Generate Image"}
            </button>
            <button
                onClick={fetchGeneratedImage}
                className="image-generator-button fetch-button"
                disabled={loading}
            >
                {loading ? "Fetching..." : "Fetch Last Image"}
            </button>
            {error && <p className="image-generator-error">{error}</p>}

            {image && <img src={image} alt="Generated" className="image-generator-image" />}


            <div className="image-generator-logs">
                {logs.length > 0 ? (
                    <pre className="image-generator-log-entry">{logs.join("\n")}</pre>
                ) : (
                    <p className="image-generator-log-placeholder">Waiting for logs...</p>
                )}
            </div>


        </div>
    );
};

export default ImageGenerator;
