import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';


const isLocal = window.location.hostname === 'localhost';

axios.defaults.baseURL = isLocal ? "http://localhost:8500" : "https://myblocks.in:7101"; 

// axios.defaults.baseURL = isLocal ? "http://localhost:5500" : "http://61.2.142.91:5500"; 

//axios.defaults.baseURL="http://61.2.142.91:7500"

//axios.defaults.baseURL="http://61.2.142.91:5500"

// axios.defaults.baseURL="http://localhost:8500"

//axios.defaults.baseURL="https://myblocks.in:7101"

//axios.defaults.baseURL="http://34.172.164.239:8500"


// function setFavicon(site) {
//   const link = document.querySelector("link[rel~='icon']");
//   link.href = `/favicon-${site}.png`;
// }

// // Retrieve the city value from the session
// const site = sessionStorage.getItem('site'); // Replace with your actual session storage logic

// // Ensure city is not null or undefined before setting the favicon
// if (site) {
//   setFavicon(site);
// }


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <div>
  <SetFavicon />
    <App />
    </div>
 // </React.StrictMode>
);

function SetFavicon() {
  useEffect(() => {
    // Retrieve the 'site' value from the session (you can use sessionStorage or any other method)
    const site = sessionStorage.getItem('site');

    // Dynamically set the favicon based on the 'site' value
    if (site === 'Myblock') {
      document.querySelector("link[rel*='icon']").href = '/myblocks final1.ico';
    } else if (site === 'Techie-Index') {
      document.querySelector("link[rel*='icon']").href = '/techieindex_logo.ico';
    }
  }, []);

  return (
    <div>
      <Helmet>
        {/* Define the default favicon */}
        <link rel="icon" href="/favicon-default.ico" />

        {/* You can also set other meta tags and title here */}
      </Helmet>

      {/* Your app content */}
    </div>
  );
}

export default SetFavicon;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
