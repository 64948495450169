import React, { useState, useEffect } from 'react';
import axios from 'axios';

function LLMDropdown({ onSelect }) {
  const [llmData, setLlmData] = useState([]);
  const [selectedLlm, setSelectedLlm] = useState('');
  const [modelNames, setModelNames] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [llmUrl, setLlmUrl] = useState('');

  useEffect(() => {
    axios.get('/llm-providers')
      .then(response => {
        setLlmData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleLlmChange = (e) => {
    const selected = e.target.value;
    setSelectedLlm(selected);
    setSelectedModel(''); // Reset model dropdown
    const filteredModels = llmData.filter(item => item.LLM_NAME === selected).map(item => item.MODEL_NAME);
    setModelNames(filteredModels);
    const url = llmData.find(item => item.LLM_NAME === selected)?.LLM_URL || '';
    setLlmUrl(url);
    onSelect(selected, '', url); // Send selected LLM with empty model and URL
  };

  const handleModelChange = (e) => {
    const model = e.target.value;
    setSelectedModel(model);
    onSelect(selectedLlm, model, llmUrl); // Send both selected LLM, model, and URL
  };

  const uniqueLlmNames = [...new Set(llmData.map(item => item.LLM_NAME))];

  return (
    <div>
      <label>LLM Provider:</label>
      <select onChange={handleLlmChange} value={selectedLlm}>
        <option value="">Select LLM Provider</option>
        {uniqueLlmNames.map((name, index) => (
          <option key={index} value={name}>{name}</option>
        ))}
      </select>

      {modelNames.length > 0 && (
        <div>
          <label>Model Name:</label>
          <select value={selectedModel} onChange={handleModelChange}>
            <option value="">Select Model</option>
            {modelNames.map((model, index) => (
              <option key={index} value={model}>{model}</option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default LLMDropdown;
