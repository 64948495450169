import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HfInference } from "@huggingface/inference";
import './ImageGeneration.css';
import LLMDetailsTable from './LLMDetailsTable';
import Cookies from "js-cookie";
import { GoogleGenerativeAI } from '@google/generative-ai';


const ImageGenerator = ({ onImageGenerated, onOpenLLMDetails, onClose }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [promptText, setPromptText] = useState('');
    const [apiFailed, setApiFailed] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [imageGenerated, setImageGenerated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apierror, setApiError] = useState('');



    const USERID = Cookies.get("userid");
    const FIRMID = Cookies.get("firmid");
    const selectedLlm = 'HUGGINGFACE-TEXTTOIMAGE';




    useEffect(() => {
        const savedImage = sessionStorage.getItem("savedImageUrl");
        console.log("savedImage", savedImage)
        if (savedImage) {
            // Check if the image URL is still accessible
            fetch(savedImage)
                .then(response => {
                    if (response.ok) {
                        setImageUrl(savedImage);
                    } else {
                        console.log("Invalid image URL, clearing session storage");
                        sessionStorage.removeItem("savedImageUrl");
                        setImageUrl(null);
                    }
                })
                .catch(error => {
                    console.log("Error loading saved image, clearing session storage:", error);
                    sessionStorage.removeItem("savedImageUrl");
                    setImageUrl(null);
                });
        }

        const storedApiKey = sessionStorage.getItem('apiKey');
        console.log("storedApiKey", storedApiKey)
        if (storedApiKey) {
            setApiKey(storedApiKey);
        } else {
            checkLLM();
        }
    }, []);

    const checkLLM = async () => {
        try {
            const response = await axios.post('/check-llm', { USERID, FIRMID, selectedLlm });
            if (response.data.success) {
                sessionStorage.setItem('apiKey', response.data.apiKey);
                setApiKey(response.data.apiKey);
            } else {
                setApiFailed(true);
            }
        } catch (error) {
            setApiFailed(true);
        }
    };

    // const generateImage = async (e) => {
    //     e.preventDefault();
    //     if (!promptText) {
    //         alert('Please enter a prompt');
    //         return;
    //     }
    //     const storedApiKey = sessionStorage.getItem('apiKey');
    //     console.log("storedApiKey", storedApiKey)

    //     const hfClient = new HfInference(apiKey);
    //     setLoading(true);

    //     try {
    //         const imageBlob = await hfClient.textToImage({
    //             model: "black-forest-labs/FLUX.1-dev",
    //             inputs: promptText,
    //             parameters: { num_inference_steps: 5 },
    //             provider: "fal-ai",
    //         });

    //         const imageUrl = URL.createObjectURL(imageBlob);
    //         setImageUrl(imageUrl);
    //         setImageGenerated(true);
    //         sessionStorage.setItem("savedImageUrl", imageUrl);
    //     } catch (error) {
    //         console.error("Error generating image:", error);
    //         setApiError(error.message);
    //     }
    //     setLoading(false);
    // };


    const contents = 'Hi, can you create a 3D rendered image of a lion with wings and a top hat flying over a happy futuristic sci-fi city with lots of greenery?';

    async function generateImage(e) {
        e.preventDefault();

        const storedApiKey = sessionStorage.getItem('apiKey');
        console.log("storedApiKey", storedApiKey)

        const apiKey = 'AIzaSyBqrxhb54ba114jtZ7kqtv4Z6c1uab46xc'; // Replace with your actual API key
        const client = new GoogleGenerativeAI({ apiKey });

        const model = client.getGenerativeModel({ model: "gemini-2.0-flash-exp" });
        const response = await model.generateContent({ contents,
            locale: "en-IN" });


        // const response = await client.models.generateContent({
        //     model: 'models/gemini-2.0-flash-exp',
        //     contents,
        //     responseModality: ['IMAGE'],
        // });

        const imagePart = response.candidates[0].content.parts.find(part => part.inlineData);
        if (imagePart) {
            const base64Data = imagePart.inlineData.data;
            const imageBlob = await (await fetch(`data:image/png;base64,${base64Data}`)).blob();
            
            // Create an Object URL and display the image
            const imageUrl = URL.createObjectURL(imageBlob);
            setImageUrl(imageUrl);
            setImageGenerated(true);
            sessionStorage.setItem("savedImageUrl", imageUrl);


        } else {
            console.log('No image data received.');
        }
    }

   



    // const handleClose = () => {
    //     sessionStorage.removeItem("savedImageUrl");
    //     onClose(); // Call the passed onClose function from props
    // };


    return (
        <div className="template-modal-overlay">



            <div className="image-generator-modal">
                <button className="close-button-top-right" onClick={onClose}>×</button>
                <h2>Generate an Image</h2>

                <button
                    onClick={(e) => {
                        e.preventDefault();
                        onOpenLLMDetails(); // Call the function to open LLMDetailsTable
                    }}
                >
                    ADD/CHANGE API KEY
                </button>
                <textarea
                    className="prompt-input"
                    placeholder="Enter prompt"
                    value={promptText}
                    onChange={(e) => setPromptText(e.target.value)}
                    rows={4}
                    cols={50}
                />
                <button className="generate-button" onClick={generateImage}>
                    {imageGenerated ? 'Regenerate' : 'Generate'}
                </button>
                {apierror && <p>{apierror}</p>}
                {imageUrl && (
                    <div className="generated-image-container">

                        {imageUrl && <img src={imageUrl} alt="Generated" />}
                        <button className="use-image-button" onClick={(e) => onImageGenerated(imageUrl, e)}>
                            Use This Image
                        </button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default ImageGenerator;
