import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gap.css';
import Cookies from 'js-cookie';

const GapAnalysis = () => {
  const [leaders, setLeaders] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const userid = Cookies.get("userid"); // Get userid from cookies

  useEffect(() => {
    const fetchLeaders = async () => {
      try {
        const response = await axios.get('/get-leaders');
        setLeaders(response.data);
      } catch (error) {
        console.error('Error fetching leaders:', error);
      }
    };

    fetchLeaders();
  }, []);

  console.log("userid",userid)

  const handleRunGapAnalysis = async () => {
    if (!selectedLeader) {
      setMessage('Please select a leader.');
      return;
    }

    setLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await axios.post('/run-gap-analysis', { leaderId: selectedLeader, userid: userid }); // Send userid along with selected leader ID
      console.log('Gap Analysis Response:', response.data);
      setMessage('Gap analysis completed successfully.');
      // Assuming the response contains some results, you could set them here
      // setMessage(`Gap analysis completed successfully. Results: ${response.data.results}`);
    } catch (error) {
      console.error('Error running gap analysis:', error);
      setError('Error running gap analysis. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="gap-container">
      <br/><br/>
      <h1>Gap Analysis</h1>
      <div className="form-group">
        <label htmlFor="leader-select" className='label-gap' >Select Leader:</label>
        <select
          id="leader-select"
          value={selectedLeader}
          onChange={(e) => setSelectedLeader(e.target.value)}
        >
          <option value="">--Select a Leader--</option>
          {leaders.map((leader) => (
            <option key={leader.id} value={leader.id}>
              {leader.name}
            </option>
          ))}
        </select>
      </div>
      <div className="button-group">
        <button onClick={handleRunGapAnalysis} disabled={loading}>
          {loading ? 'Running...' : 'Run Gap Analysis'}
        </button>
      </div>
    </div>
  );
};

export default GapAnalysis;
