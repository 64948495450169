import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const LLMDetailsTable = ({ onActivate, fetchLLMDetails, llmDetails = [] }) => {

  
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerDetails, setProviderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const userid = Cookies.get("userid");
  const firmid = Cookies.get("firmid");

 

  console.log("llmDetails",llmDetails)

  console.log("llmDetails", llmDetails, Array.isArray(llmDetails));




  const toggleStatus = async (id, provider) => {
    try {
      const response = await axios.post("/api/toggle-status", {
        id,
        userid,
        firmid,
      });

      if (response.data.success) {
        fetchLLMDetails(); // Refresh the table after updating status
        // onSelectProvider(provider); // Pass selected provider to parent
        onActivate(provider);
      }
    } catch (err) {
      alert("Failed to update status.");
    }
  };

  const fetchProviderDetails = async (provider) => {
    setLoading(true);
    setError("");
    setSelectedProvider(provider);

    try {
      const response = await axios.get("/api/get-llm-details", {
        params: { provider },
      });
      setProviderDetails(response.data);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  return (
    <div>
      <h1>LLM Details</h1>
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">ID</th>
            <th className="border border-gray-300 px-4 py-2">LLM Provider</th>
            <th className="border border-gray-300 px-4 py-2">API Key</th>
            <th className="border border-gray-300 px-4 py-2">Insert Date</th>
            <th className="border border-gray-300 px-4 py-2">Update Date</th>
            <th className="border border-gray-300 px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {llmDetails.length > 0 ? (
            llmDetails.map((detail) => (
              <tr key={detail.ID}>
                <td className="border border-gray-300 px-4 py-2">{detail.ID}</td>
                <td
                  className="border border-gray-300 px-4 py-2 cursor-pointer text-blue-600 hover:underline"
                  onClick={() => fetchProviderDetails(detail.LLM_PROVIDER)}
                >
                  {detail.LLM_PROVIDER}
                </td>
                <td className="border border-gray-300 px-4 py-2">{detail.API_KEY}</td>
                <td className="border border-gray-300 px-4 py-2">{detail.INSRT_DTM}</td>
                <td className="border border-gray-300 px-4 py-2">{detail.UPD_DTM}</td>
                <td className="border border-gray-300 px-4 py-2">
                  {detail.STATUS === "ACTIVE" ? (
                    "ACTIVE"
                  ) : (
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                      onClick={() => toggleStatus(detail.ID, detail.LLM_PROVIDER)}
                    >
                      Activate
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="border border-gray-300 px-4 py-2" colSpan="6">
                No data found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {selectedProvider && (
        <div className="mt-4 p-4 border rounded-lg bg-gray-100">
          <h3 className="text-lg font-semibold">Details for: {selectedProvider}</h3>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : providerDetails ? (
            <pre className="bg-gray-200 p-2 rounded">{JSON.stringify(providerDetails, null, 2)}</pre>
          ) : (
            <p>No details found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default LLMDetailsTable;
