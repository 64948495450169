import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import './LLMDetailsTable.css'

const LLMDetailsTable = ({ onClose }) => {
  const [llmDetails, setLlmDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const userid = Cookies.get("userid");
  const firmid = Cookies.get("firmid");
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    fetchLLMDetails();
  }, []);

  const fetchLLMDetails = async () => {


    if (!userid || !firmid) {
      setError("USERID and FIRMID cookies are missing.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get("/api/get-llm-details-imagegen", {
        params: { userid, firmid },
      });
      setLlmDetails(response.data);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const toggleStatus = async (id,apikey) => {


    try {
      const response = await axios.post("/api/toggle-status-imagegen", {
        id,
        userid,
        firmid,
      });

      if (response.data.success) {
        fetchLLMDetails(); // Refresh the table after updating status
        sessionStorage.setItem('apiKey', apikey);

        const storedApiKey = sessionStorage.getItem('apiKey');
        console.log("storedApiKey",storedApiKey)
      }
    } catch (err) {
      alert("Failed to update status.");
    }
  };

  const handleAddLLM = async (e) => {
    e.preventDefault();
    if (!apiKey.trim()) {
      alert("API Key is required.");
      return;
    }

    try {
      const response = await axios.post("/api/add-llm", {
        userid,
        firmid,
        apiKey,
        llmProvider: "HUGGINGFACE-TEXTTOIMAGE",
      });

      if (response.data.success) {
        sessionStorage.setItem('apiKey', apiKey);
        fetchLLMDetails();
        setApiKey(""); // Clear input after success
      } else {
        alert("Failed to add LLM.");
      }
    } catch (err) {
      alert("Error adding LLM.");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="llm-details-modal">
      <div className="llm-details-container">
        <button className="close-llm-button" onClick={(e) => {
          e.preventDefault();
          onClose();
        }}>
          ✖ Close
        </button>

        <h1>LLM Details</h1>

         {/* Input Field for API Key */}
         <div className="mb-4">
          <input
            type="text"
            placeholder="Enter API Key"
            value={apiKey}
            onChange={(e) => {
              e.preventDefault();
              setApiKey(e.target.value)

            }
            }
            className="border px-4 py-2 w-full"
          />
          <button
              onClick={(e) => handleAddLLM(e)}

            className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Submit
          </button>
        </div>


        <table className="llm-details-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>LLM Provider</th>
              <th>API Key</th>
              <th>Insert Date</th>
              <th>Update Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {llmDetails.length > 0 ? (
              llmDetails.map((detail) => (
                <tr key={detail.ID}>
                  <td>{detail.ID}</td>
                  <td>{detail.LLM_PROVIDER}</td>
                  <td>{detail.API_KEY}</td>
                  <td>{detail.INSRT_DTM}</td>
                  <td>{detail.UPD_DTM}</td>
                  <td>
                    {detail.STATUS === "ACTIVE" ? (
                      "ACTIVE"
                    ) : (
                      <button className="activate-button" onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(detail.ID,detail.API_KEY)
                      }
                      }>
                        Activate
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No data found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LLMDetailsTable;
